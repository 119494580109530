<template>
  <div class="container">
    <div class="head">
      <img src="../../assets/images/yuwan/registrationHead.jpg" />
    </div>
    <div class="content">
      <div class="baseInfo"></div>
      <div class="baseInfo">
        <div v-if="dataList.length > 0">
          <div
            v-for="(item, index) in dataList"
            :key="index"
            style="margin-top: 10px"
          >
            <div
              v-if="
                item &&
                item.prize_team_use_info &&
                item.prize_team_use_info.length > 0
              "
            >
              <div v-if="item.track == '创新'">
                <div class="matchTitle">创新赛</div>
                <div class="baseTitle">基本信息</div>
                <div class="infoMassage">
                  <div class="leftTit">队长姓名:</div>
                  <div class="pass_input">
                    <span>{{ item.name }}</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">队名:</div>
                  <div class="pass_input">
                    <span>{{ item.team_name }}</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">学校:</div>
                  <div class="pass_input">
                    <span>{{ item.school }}</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">考试组别:</div>
                  <div class="pass_input">
                    <span>{{ item.track }}</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">准考证号:</div>
                  <div class="pass_input">
                    <span>{{ item.examination_card_number }}</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">奖项:</div>
                  <div class="pass_input">
                    <span>{{ item.prize_team_use_info[0].prize }}</span>
                  </div>
                </div>
              </div>

              <div v-else-if="item.track == '学生'">
                <div class="matchTitle">工程实践赛</div>
                <div class="baseTitle">基本信息</div>
                <div class="infoMassage">
                  <div class="leftTit">姓名1:</div>
                  <div class="pass_input">
                    <span v-if="item.prize_team_member_info.length > 0">{{
                      item.prize_team_member_info[0].name || ""
                    }}</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">姓名2:</div>
                  <div class="pass_input">
                    <span v-if="item.prize_team_member_info.length > 0">{{
                      item.prize_team_member_info[1].name || ""
                    }}</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">学校:</div>
                  <div class="pass_input">
                    <span>{{ item.school }}</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">考试组别:</div>
                  <div class="pass_input">
                    <span>{{ item.group }}</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">参赛证号:</div>
                  <div class="pass_input">
                    <span>{{ item.examination_card_number }}</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">奖项:</div>
                  <div class="pass_input">
                    <span>{{ item.prize_team_use_info[0].prize }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="paymentsTit">收款信息</div>
              <div v-if="!item.paymentsInfo">
                <div class="paymentsContent">
                  <div class="paymentsBtn">
                    <el-button class="btn" type="primary" @click="next(item)"
                      >填写收款信息</el-button
                    >
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="infoMassage">
                  <div class="leftTit">收款人姓名:</div>
                  <div class="pass_input">
                    <el-input
                      v-model="item.paymentsInfo.receiver_name"
                      placeholder="请填写姓名"
                    />
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">手机号:</div>
                  <div class="pass_input">
                    <el-input
                      maxlength="11"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      v-model="item.paymentsInfo.receiver_phone"
                      placeholder="请填写手机号"
                    />
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">开户行:</div>
                  <div class="pass_input">
                    <el-input
                      v-model="item.paymentsInfo.bank_name"
                      placeholder="请填写开户行"
                    />
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">收款账户:</div>
                  <div class="pass_input">
                    <el-input
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      v-model="item.paymentsInfo.receiver_account"
                      placeholder="请填写收款账户"
                    />
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">银行联行号:</div>
                  <div class="pass_input">
                    <el-input
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      v-model="item.paymentsInfo.bank_connection_number"
                      placeholder="请填写银行联行号"
                    />
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">收款人身份证:</div>
                  <div class="pass_input">
                    <el-input
                      maxlength="18"
                      v-model="item.paymentsInfo.receiver_id_num"
                      placeholder="请填写身份证"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="descTitle">
        <p class="matter">&nbsp;&nbsp;提示信息：</p>
        <p>奖金发放登记截止时间为11月24日24：00，请获奖队伍尽快完成登记。</p>
        <p>
          1、一组队伍只允许填写一次收款信息，以先填写的信息为准。（赛道二由队长填写）
        </p>
        <p>2、请仔细核对并如实填写收款信息，一经填写无法修改。</p>
        <p>
          3、登记信息时请填写身份证号，由竞赛方代缴个人偶然所得税（20%），奖金以实收金额为准。
        </p>
        <p>
          4、奖金发放周期为登记截止后15个工作日内。发放完成后由参赛队伍自行分配。
        </p>
        <p>
          5、如更换手机号无法验证，请优先使用其他队员手机进行验证。仍无法验证的请使用竞赛报名登记的邮箱发送邮件至dtcupzuwei@126.com，提供与报名信息一致的收款信息。
        </p>
      </div>
    </div>
  </div>
</template>
 
<script>
import { ElMessage } from "element-plus";
import { competition } from "api/prize.js";
export default {
  data() {
    return {
      phone: "",
      dataList: [],
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.dataList = [];
      this.phone = this.$route.query.phone;
      localStorage.setItem("phone", this.phone);
      let idStr = this.$route.query.idList || "";
      localStorage.setItem("idList", idStr);
      let idList = idStr.split(",") || [];
      let obj = new FormData();
      let team_ids = [];
      obj.append("phone", this.phone);
      obj.append("team_ids", idStr);
      idList.forEach((element) => {
        let param = new FormData();
        param.append("phone", this.phone);
        param.append("team_id", element);
        competition.select(param).then((response) => {
          if (response.code === 200) {
            let item = JSON.parse(response.info);
            competition.payments(param).then((res) => {
              //查询填写收款信息
              let paymentsInfo = JSON.parse(res.info);
              item.paymentsInfo = paymentsInfo ? paymentsInfo : "";
              this.dataList.push(item);
              team_ids.push(element);
              console.log(this.dataList);
            });
          }
        });
      });
    },
    next(item) {
      localStorage.setItem("team_id", item.team_id);
      localStorage.setItem("track", item.track);
      localStorage.setItem("group", item.group);
      this.$router.push({
        path: "/paymentsInformation",
        query: { team_id: item.team_id, track: item.track },
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "./InformationCompetition.scss";
</style>